import { createApp } from "vue";
import App from "./App.vue";

import AOS from 'aos'
import 'aos/dist/aos.css'

const app = createApp(App);

app.use(AOS.init({
  // Global settings
  startEvent: "DOMContentLoaded", // name of the event dispatched on the document, that AOS should initialize on
  initClassName: "aos-init", // class applied after initialization
  animatedClassName: "aos-animate", // class applied on animation
  useClassNames: true, // if true, will add content of `data-aos` as classes on scroll

  // Specific settings
  duration: 1000
}));

app.mount('#app')
