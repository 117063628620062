<template>
  <div id="footer">
    <div class="footer">
      <p>© 2021 - Créé par <a href="sarahveysset.com"> Sarah Veysset </a></p>
      <div class="social-media">
        <a href="https://www.facebook.com/Barryosteopathe" target="_blank">
          <img src="../assets/logo-facebook.png" />
        </a>
        <a href="https://www.instagram.com/barry_osteo/" target="_blank">
          <img src="../assets/logo-instagram.png" />
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Footer",
};
</script>

<style lang="scss">
#footer {
  width: 100%;
  height: 70px;
  background-color: #1b4764;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .footer {
    width: 80%;
    margin: auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    p {
      font-size: 14px;
      font-weight: 200;
      letter-spacing: 0.1rem;
    }

    a {
      text-decoration: none;
      color: #ffffff;
    }

    .social-media {
      a:first-child {
        margin-right: 1rem;
      }

      img {
        height: 22px;
        transition: all 0.1s;

        &:hover {
          transform: scale(1.1);
        }
      }
    }
  }
}

@media all and (max-device-width: 768px) {
  #footer {
    height: 100%;

    .footer {
      flex-direction: column;
      padding: 1rem 0;

      .social-media {
        margin-top: 1rem;
      }
    }
  }
}
</style>
