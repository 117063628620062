<template>
  <Header />
  <Homepage />
  <Footer />
</template>

<style>
@import "./styles/reset.scss";
@import "./styles/font.scss";
@import "./styles/global.scss";
</style>

<script>
import Header from "./components/Header.vue";
import Homepage from "./pages/Homepage.vue";
import Footer from "./components/Footer.vue";

export default {
  name: "App",
  components: {
    Header,
    Homepage,
    Footer,
  },
};
</script>

<style lang="scss">
#app {
  font-family: "Poppins", sans-serif;
}
</style>
