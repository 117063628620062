<template>
  <section id="schedule-container" class="ancre">
    <div class="schedule">
      <div class="schedule-content">
        <div
          v-for="schedule in schedules"
          :key="schedule.uuid"
          data-aos="fade-up"
        >
          <h3>
            <i class="material-icons">{{ schedule.icon }}</i
            >{{ schedule.title }}
          </h3>
          <p v-html="schedule.text"></p>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "schedule",
  data() {
    return {
      schedules: [
        {
          icon: "home",
          title: "LE CABINET",
          text: "2 place du champs de Mars <br /> 26290 Donzère",
        },
        {
          icon: "access_time",
          title: "NOS HORAIRES",
          text:
            "Du lundi au vendredi <br /> 8h - 13h et 14h - 19h <br /><br /> * Samedi matin pour les étudiants et les travailleurs en déplacement (uniquement par téléphone)",
        },
        {
          icon: "call",
          title: "NOUS CONTACTER",
          text: "osteo.barry@gmail.com <br /> 07 63 10 58 26",
        },
      ],
    };
  },
};
</script>

<style lang="scss">
#schedule-container {
  margin-top: 3rem;
  background-color: #1b4764;
  color: #ffffff;

  .schedule {
    width: 60%;
    margin: auto;
    padding: 6rem;

    .schedule-content {
      display: flex;
      flex-direction: row;
      text-align: center;

      & > div {
        flex: 1;
      }

      div:first-child {
        margin-top: 0;
      }

      h3 {
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 300;
        letter-spacing: 0.1rem;

        .material-icons {
          margin-right: 12px;
        }
      }

      p {
        margin-top: 1.5rem;
        font-weight: 200;
        line-height: 1.5;
        letter-spacing: 0.1rem;
      }
    }
  }

  @media all and (max-device-width: 768px) {
    .schedule {
      padding: 3rem 0;

      .schedule-content {
        flex-direction: column;
        align-items: left;

        & > div {
          margin-top: 2rem;
        }

        h3 {
          justify-content: center;
        }
      }
    }
  }
}
</style>
