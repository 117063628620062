<template>
  <div id="header">
    <div class="header" data-aos="fade-down">
      <div class="navbar-desktop">
        <a href="#presentation-container">
          <img src="../assets/logo-osteo-barry.png" />
        </a>
        <div class="menu-mobile" @click="opened = !opened">
          <span></span>
          <span></span>
          <span></span>
        </div>
        <div class="menu-desktop">
          <ul>
            <li><a href="#presentation-container">Accueil</a></li>
            <li><a href="#booking-container">Prise de rendez-vous</a></li>
            <li><a href="#training-container">Formation</a></li>
            <li><a href="#company-container">Entreprise</a></li>
            <li><a href="#testimonial-container">Avis</a></li>
            <li><a href="#rates-container">Tarifs</a></li>
            <li><a href="#schedule-container">Contact</a></li>
          </ul>
        </div>
      </div>
    </div>
    <transition name="slide">
      <div v-if="opened" class="navbar-mobile">
        <ul>
          <li @click="close()">
            <a href="#presentation-container">Accueil</a>
          </li>
          <li @click="close()">
            <a href="#booking-container">Prise de rendez-vous</a>
          </li>
          <li @click="close()"><a href="#training-container">Formation</a></li>
          <li @click="close()"><a href="#company-container">Entreprise</a></li>
          <li @click="close()"><a href="#testimonial-container">Avis</a></li>
          <li @click="close()"><a href="#rates-container">Tarifs</a></li>
          <li @click="close()"><a href="#schedule-container">Contact</a></li>
        </ul>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  name: 'Header',
  data() {
    return {
      opened: false,
    }
  },
  methods: {
    close() {
      this.opened = false
    },
  },
}
</script>

<style lang="scss" scoped>
$textColor: #002e4e;

.slide-enter-active,
.slide-leave-active {
  transition: all 1s ease-in-out;
}

.slide-enter-to {
  position: absolute;
  top: 0;
}

.slide-enter-from {
  position: absolute;
  top: -50%;
}

.slide-leave-to {
  position: absolute;
  top: -50%;
}

.slide-leave-from {
  position: absolute;
  top: 0;
}

#header {
  .header {
    width: 100%;
    height: 80px;
    position: fixed;
    z-index: 2;
    display: flex;
    align-items: center;
    background-color: #ffffff;
    box-shadow: 10px 5px 5px rgba(0, 0, 0, 0.025);

    .navbar-desktop {
      width: 80%;
      margin: auto;
      display: flex;
      justify-content: space-between;
      align-items: center;

      img {
        width: 200px;
      }

      .menu-mobile {
        display: none;
        width: 20px;
        cursor: pointer;

        span {
          display: block;
          background: $textColor;
          height: 2px;
          margin: 3px 0;
          transition: 0.4s cubic-bezier(0.68, -0.6, 0.32, 1.6);
        }

        span:nth-of-type(1) {
          width: 50%;
        }

        span:nth-of-type(2) {
          width: 100%;
        }

        span:nth-of-type(3) {
          width: 75%;
        }

        @media all and (max-device-width: 768px) {
          display: block;
        }
      }

      .menu-desktop {
        display: block;

        li {
          display: inline;
          margin: 0 16px;
          text-transform: uppercase;
          font-size: 14px;
          font-weight: 300;
          letter-spacing: 0.1rem;

          &:last-child {
            margin-right: 0;
          }
        }

        a {
          text-decoration: none;
          border: none;
          color: $textColor;
          transition: color 0.3s ease;

          &:hover {
            color: #8eb6d1;
          }
        }
      }

      @media all and (max-device-width: 768px) {
        img {
          width: 144px;
        }

        .menu-desktop {
          display: none;
        }
      }
    }
  }

  .navbar-mobile {
    width: 100%;
    position: fixed;
    z-index: 1;
    margin-top: 80px;
    background-color: #ffffff;
    box-shadow: 10px 5px 5px rgba(0, 0, 0, 0.025);
    padding: 1rem 0;

    li {
      text-align: center;
      padding: 0.5rem 0;

      a {
        text-decoration: none;
        color: $textColor;
        transition: color 0.3s ease;

        &:hover {
          color: #8eb6d1;
        }
      }
    }
  }
}
</style>
