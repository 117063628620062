<template>
  <section id="booking-container" class="ancre">
    <div class="booking">
      <h2
        class="title-section"
        data-aos="fade-up"
        data-aos-delay="50"
        data-aos-duration="1000"
      >
        Prendre un rendez-vous
      </h2>

      <p class="temporary">
        Lors de mon absence vous pouvez prendre rendez-vous avec ma remplaçante
        mathilde cerdan :
        <a
          href="https://book.timify.com/?accountId=66a74d950621db058050a1f0&hideCloseButton=true"
        >
          Cliquez ici pour prendre RDV
        </a>
      </p>

      <iframe
        title="Module de réservation"
        width="600"
        height="400"
        src="https://book.timify.com?accountId=60a7742a7e4a4c118275bb4d&fullscreen=1&hideCloseButton=1&locale=fr-fr"
      ></iframe>
    </div>
  </section>
</template>

<script>
export default {
  name: "booking",
}
</script>

<style lang="scss">
#booking-container {
  .booking {
    text-align: center;
    margin: 5rem 0;

    .temporary {
      margin: 2rem 1rem 0 1rem;
      color: #002e4e;
    }

    iframe {
      margin-top: 3rem;
    }
  }

  @media all and (max-device-width: 768px) {
    .booking {
      margin: 8rem 0 0 0;

      iframe {
        width: 290px;
      }
    }
  }
}
</style>
