<template>
  <section id="company-container" class="ancre">
    <div class="company">
      <h2
        class="title-section title"
        data-aos="fade-up"
        data-aos-delay="50"
        data-aos-duration="1000"
      >
        Entreprise
      </h2>

      <div
        class="content"
        data-aos="fade-up"
        data-aos-delay="50"
        data-aos-duration="1000"
      >
        <img :src="require('@/assets/cabinet-ostheopathe-barry.jpg')" />

        <p class="text">
          Afin de limiter les arrêts de travail liés au trouble
          musculo-squelettique (TMS), les ostéopathes peuvent intervenir
          directement en entreprise. Pour ma part, j’ai collaboré avec : <br />

          <ul>
            <li>Veolia </li>
            <li> Bricomarché </li>
            <li> DPD </li>
            <li> France boisson </li>
            <li> TMP/roustant </li>
            <li> NTN-SNR </li>
          </ul>

          Si vous souhaitez collaborer avec moi, vous pouvez me joindre par
          téléphone ou par mail.
        </p>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'Company',
}
</script>

<style lang="scss">
#company-container {
  .company {
    margin: 5rem 0;

    .title {
      text-align: center;
    }

    .content {
      width: 80%;
      height: 100%;
      margin: auto;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 2rem;
      padding: 3rem;
      color: #002e4e;
      line-height: 2;

      img {
        width: 40%;
        margin-right: 5rem;
      }

      ul {
        margin-top: 1rem;
        list-style: disc;
      }
    }
  }

  @media all and (max-device-width: 768px) {
    .company {
      .content {
        margin-bottom: 43px;
        padding: 0;
        flex-direction: column;

        img {
          width: 100%;
          margin-right: 0;
        }

        .text {
          margin-top: 2rem;
        }
      }
    }
  }
}
</style>
