<template>
  <section id="mosaic-container">
    <div class="mosaic">
      <div class="content top">
        <div class="block" data-aos="zoom-in"></div>
        <img
          class="one"
          data-aos="zoom-in"
          :src="require('@/assets/cabinet-barry.jpg')"
        />
        <div class="block" data-aos="zoom-in"></div>
      </div>
      <div class="content">
        <img
          class="two"
          data-aos="zoom-in"
          :src="require('@/assets/cabinet-osteo-barry.jpg')"
        />
        <div class="block" data-aos="zoom-in"></div>
        <img
          class="three"
          data-aos="zoom-in"
          :src="require('@/assets/cabinet-ostheopathe-barry.jpg')"
        />
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "mosaic",
};
</script>

<style lang="scss">
#mosaic-container {
  margin-top: 5rem;

  .mosaic {
    width: 80%;
    margin: auto;

    .top {
      margin-bottom: 2rem;
    }

    .content {
      display: flex;
      flex-direction: row;

      .block {
        width: 100%;
        background-color: #1b4764;
      }

      .one {
        margin: 0 2rem;
      }

      .two {
        margin-right: 2rem;
      }

      .three {
        margin-left: 2rem;
      }
    }
  }

  img {
    width: 100%;
    height: 320px;
    object-fit: cover;
  }

  @media all and (max-device-width: 768px) {
    margin-top: 2rem;

    .mosaic {
      .content {
        flex-direction: column;

        .one,
        .two,
        .three {
          margin: 0;
        }

        .two {
          margin-bottom: 2rem;
        }
      }
    }
  }
}
</style>
