<template>
  <section id="presentation-container" :style="style">
    <div class="presentation">
      <h1 data-aos="fade-right">
        CABINET <br />
        D'OSTÉOPATHIE <br />
        BARRY
      </h1>
    </div>
    <div class="banner"></div>
  </section>
</template>

<script>
export default {
  name: "presentation",
  data() {
    return {
      style: {
        backgroundImage: `url(${require('@/assets/dos-manipulation.png')})`
      }
    }
  }
};
</script>

<style lang="scss">
$textColor: #002e4e;

#presentation-container {
  width: 100vw;
  height: 100vh;
  background-size: cover;

  .presentation {
    width: 80%;
    height: 100%;
    margin: auto;
    display: flex;
    align-items: center;
    position: relative;

    h1 {
      font-size: 70px;
      font-weight: 200;
      line-height: 1.4;
      letter-spacing: 0.7rem;
      color: $textColor;
    }
  }

  .banner {
    width: 100%;
    height: 120px;
    background-color: #1b4764;
    position: absolute;
    bottom: 0;
  }

  @media all and (max-device-width: 768px) {
    height: auto;

    .presentation {
      padding-top: 80px;

      h1 {
        font-size: 20px;
        padding: 3rem 0;
        letter-spacing: 0.4rem;
      }
    }

    .banner {
      height: 90px;
      bottom: initial;
    }
  }
}
</style>
