<template>
  <section id="testimonial-container" class="ancre">
    <div class="testimonial">
      <div class="header" data-aos="fade-up">
        <h2 class="title-section">LES AVIS</h2>
        <span>“</span>
      </div>

      <VueAgile>
        <div
          class="slide"
          v-for="testimonial in testimonials"
          :key="testimonial.uuid"
        >
          <h4>{{ testimonial.description }}</h4>
          <p>{{ testimonial.auteur }}</p>
        </div>
      </VueAgile>
    </div>
  </section>
</template>

<script>
import { VueAgile } from 'vue-agile'

export default {
  name: 'testimonial',
  components: {
    VueAgile,
  },
  data() {
    return {
      testimonials: [
        {
          description:
            'Merci pour les conseils et les explications, bonne thérapeute. De plus le lieu est convivial et chaleureux.',
          auteur: 'Sylvain',
        },
        {
          description:
            "Un grand merci de m'avoir soulagé les lombaires. Vous avez des mains magiques, douces, chaudes et professionnelles. Merci encore.",
          auteur: 'Laurence Viviano',
        },
        {
          description: 'Vraiment efficace et professionnelle.',
          auteur: 'Angelo Viviano',
        },
        {
          description:
            "Très bonne ostéopathe... à l'écoute.. je recommande vivement !",
          auteur: 'Michael G.',
        },
        {
          description:
            "Super expérience avec Madame Barry, ostéopathe très à l'écoute et méticuleuse. Très pro, je recommande !",
          auteur: 'Laurie A.',
        },
      ],
    }
  },
}
</script>

<style lang="scss">
#testimonial-container {
  .testimonial {
    margin: auto;
    width: 40%;
    margin-top: 4rem;
    padding: 3rem;
    text-align: center;
    position: relative;

    .header {
      display: flex;
      justify-content: center;
      align-items: center;

      h2 {
        letter-spacing: 16px;
      }

      span {
        position: absolute;
        z-index: -1;
        top: -10px;
        font-family: initial;
        font-size: 280px;
        color: #c1d7e8;
      }
    }

    .slide {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      color: #002e4e;

      h3 {
        font-size: 26px;
        font-weight: 300;
        text-align: center;
      }

      p {
        margin-top: 2rem;
        font-size: 18px;
        color: #68a0c5;
      }
    }

    @keyframes slideUp {
      0% {
        transform: translateY(0);
      }
      50% {
        transform: translateY(15px);
      }
      100% {
        transform: translateY(0);
      }
    }
  }

  .agile {
    width: 100%;
    margin-top: 6rem;

    h4 {
      font-size: 20px;
      font-weight: 300;
      letter-spacing: 1px;
    }

    .agile__actions {
      margin-top: 20px;

      .agile__nav-button {
        background: transparent;
        border: none;
        color: #ccc;
        cursor: pointer;
        font-size: 80px;
        transition-duration: 0.3s;
      }
      .agile__nav-button:hover {
        color: #888;
      }
      .agile__dots {
        .agile__dot {
          margin: 0 10px;
        }
      }
      .agile__dot button {
        background-color: #eee;
        border: none;
        border-radius: 50%;
        cursor: pointer;
        display: block;
        height: 10px;
        font-size: 0;
        line-height: 0;
        margin: 0;
        padding: 0;
        transition-duration: 0.3s;
        width: 10px;
      }
      .agile__dot--current button,
      .agile__dot:hover button {
        background-color: #888;
      }
    }
  }

  @media all and (max-device-width: 768px) {
    .testimonial {
      width: 80%;
      padding: 0;

      .header {
        span {
          top: -40px;
        }
      }

      .agile {
        h4 {
          font-size: 16px;
        }

        .agile__actions {
          .agile__nav-button {
            font-size: 36px;
          }
        }
      }
    }
  }
}
</style>
