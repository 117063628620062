<template>
  <section id="rates-container" class="ancre">
    <div class="rates">
      <h2 class="title-section" data-aos="fade-up">Les tarifs</h2>
      <div class="rates-content">
        <div v-for="rate in rates" :key="rate.uuid" data-aos="fade-up">
          <h3>{{ rate.title }}</h3>
          <div class="content">
            <h4>{{ rate.rate }}</h4>
            <p>{{ rate.age }}</p>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'rates',
  data() {
    return {
      rates: [
        {
          title: 'Nourisson',
          age: 'De 0 à 3 ans',
          rate: '45€',
        },
        {
          title: 'Étudiant',
          rate: '45€',
        },
        {
          title: 'Enfant/adulte',
          age: 'À partir de 4 ans',
          rate: '55€',
        },
        {
          title: 'Domicile',
          age: 'En fonction du kilométrage',
          rate: '(+/-) 65€',
        },
      ],
    }
  },
}
</script>

<style lang="scss">
#rates-container {
  margin-top: 3rem;

  .rates {
    width: 65%;
    margin: auto;
    text-align: center;

    .rates-content {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      grid-gap: 2rem;
      margin-top: 4rem;
      color: #002e4e;

      & > div {
        height: 300px;
        background-color: #f8f8f8;
        box-shadow: 0px 0px 8px 2px rgba(0, 0, 0, 0.15);

        &:nth-child(1) {
          h3 {
            background-color: #b5cad8;
          }
        }

        &:nth-child(2) {
          h3 {
            background-color: #8eb6d1;
          }
        }

        &:nth-child(3) {
          h3 {
            background-color: #68a0c5;
          }
        }

        &:nth-child(4) {
          h3 {
            background-color: #3c779e;
          }
        }

        h3 {
          padding: 2rem;
          font-size: 18px;
          font-weight: 400;
          letter-spacing: 0.1rem;
          color: #ffffff;
        }

        .content {
          margin: 3rem 0;

          h4 {
            font-size: 40px;
            font-weight: 200;
          }

          p {
            margin-top: 2rem;
            font-size: 18px;
            letter-spacing: 0.1rem;
          }
        }
      }
    }
  }

  @media all and (max-device-width: 768px) {
    .rates {
      width: 80%;

      .rates-content {
        grid-template-columns: repeat(2, 1fr);
      }
    }
  }

  @media all and (max-device-width: 488px) {
    .rates {
      .rates-content {
        grid-template-columns: repeat(1, 1fr);
      }
    }
  }
}
</style>
