<template>
  <section id="training-container" class="ancre">
    <div class="training">
      <div class="training-content-media">
        <img :src="require('@/assets/lauren-barry.jpeg')" />
      </div>

      <div class="training-content-text">
        <h2 class="title-section" data-aos="fade-up">Mes formations</h2>

        <p>
          Je suis <span> Lauren Barry </span>, ostéopathe D.O
          <span> (Diplômé en Ostéopathie) </span>, après avoir suivi une
          formation sur 5 ans à l’Institut Supérieur d’Ostéopathie (ISOstéo) de
          Lyon. J’y ai étudié l’anatomie, la physiologie et la sémiologie
          théoriquement. En pratique, j’ai appris le structurel, le viscéral, le
          crânien, mais aussi la prise en charge des femmes enceintes, des
          personnes âgés, des sportifs, des enfants, des immunodéprimés, des
          troubles gynécologiques/endocriniens et urologiques, des troubles
          psychosomatiques, …
        </p>

        <div @click="display = !display" class="tooltip">
          <p>+ En savoir plus</p>
        </div>

        <div v-if="display">
          <p>
            Tout cet apprentissage me permet d’utiliser une multitude de
            techniques afin de vous prendre en charge dans votre globalité
            (hormis contre-indication). Puisque l’ostéopathie est de première
            intention (vous n’avez pas besoin d’une ordonnance médicale pour
            consulter), je peux réaliser un examen clinique afin de vous
            réorienter au mieux si ce n’est pas dans mon champ de compétence. En
            dehors de ma formation de base, j’ai suivi une spécialisation pour
            la prise en charge des nourrissons en 2021 et pour les sportifs en
            2022. C’est avec toutes ces compétences que je réalise des
            consultations à mon cabinet sur Donzère, à domicile, dans les clubs
            sportifs (rugby, badminton,…) et en entreprise. Dans le cadre de
            prise en charge médical (par exemple en préopératoire), je suis
            amenée à réaliser des séances de palper rouler.
          </p>

          <div class="diploma">
            <img :src="require('@/assets/diplome-isosteo.png')" />

            <img :src="require('@/assets/diplome-nourisson.jpg')" />

            <img :src="require('@/assets/diplome-sportif.jpg')" />
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'training',
  data() {
    return {
      display: false,
    }
  },
}
</script>

<style lang="scss" scoped>
#training-container {
  position: relative;
  margin-top: 10rem;

  .training {
    background-color: #88685c;
    display: flex;
    flex-direction: row;
    margin: auto;
    width: 80%;
    padding: 3rem;
    box-sizing: border-box;

    p {
      text-align: justify;
      margin-top: 2rem;
      line-height: 2;
      color: #ffffff;
    }

    span {
      font-weight: 700;
    }

    .training-content-media {
      margin-top: -6rem;

      img {
        width: 300px;
        margin-right: 8rem;
      }
    }

    .training-content-text {
      .tooltip {
        display: flex;
        justify-content: flex-end;

        p {
          position: relative;
          z-index: 1;
          cursor: pointer;
          border: 2px solid #ffffff;
          padding: 0.5rem 1rem;
        }

        p:after {
          position: absolute;
          content: '';
          width: 0;
          height: 100%;
          top: 0;
          left: 0;
          direction: rtl;
          z-index: -1;
          background: #ffffff;
          transition: all 0.3s ease;
        }

        p:hover {
          color: #002e4e;
        }

        p:hover:after {
          left: auto;
          right: 0;
          width: 100%;
        }

        p:active {
          top: 2px;
        }
      }

      .diploma {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        gap: 1rem;
        margin-top: 2rem;

        img {
          max-width: 100%;
          cursor: pointer;
          transition: transform 0.3s;

          &:hover {
            transform: scale(2.5);
          }
        }
      }
    }
  }

  @media all and (max-device-width: 768px) {
    .training {
      flex-direction: column;
      padding: 2rem;

      .training-content-media {
        img {
          width: 100%;
          margin-right: 0;
        }
      }

      .training-content-text {
        margin-top: 2rem;

        p {
          text-align: left;
          word-break: break-word;
        }

        .tooltip {
          justify-content: center;
        }

        .diploma {
          grid-template-columns: 1fr;
        }
      }
    }
  }
}
</style>
