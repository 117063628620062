<template>
  <section id="location-container" class="ancre">
    <div class="location">
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2848.5082343526296!2d4.709999215900865!3d44.44324877910222!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x12b50d4357efbfc7%3A0x876e194d686fb451!2s2%20Pl.%20du%20Champs%20de%20Mars%2C%2026290%20Donz%C3%A8re!5e0!3m2!1sfr!2sfr!4v1632257768498!5m2!1sfr!2sfr"
        width="100%"
        height="100%"
        style="border:0;"
        allowfullscreen=""
        loading="lazy"
      ></iframe>
    </div>
  </section>
</template>

<script>
export default {
  name: "location",
};
</script>

<style lang="scss">
#location-container {
  .location {
    height: 350px;
  }
}
</style>
