<template>
  <div id="main">
    <Presentation />

    <Booking />

    <Training />

    <Company />

    <Testimonial />

    <Mosaic />

    <Rates />

    <Schedule />

    <Location />
  </div>
</template>

<script>
import Presentation from '../components/homepage/Presentation'
import Booking from '../components/homepage/Booking'
import Training from '../components/homepage/Training'
import Company from '../components/homepage/Company'
import Mosaic from '../components/homepage/Mosaic'
import Testimonial from '../components/homepage/Testimonial'
import Rates from '../components/homepage/Rates'
import Schedule from '../components/homepage/Schedule'
import Location from '../components/homepage/Location'

export default {
  name: 'Homepage',
  components: {
    Presentation,
    Booking,
    Training,
    Company,
    Mosaic,
    Testimonial,
    Rates,
    Schedule,
    Location,
  },
}
</script>

<style lang="scss">
$textColor: #002e4e;

#main {
  width: 100%;
  height: 100%;

  .title-section {
    font-size: 30px;
    font-weight: 200;
    letter-spacing: 0.1rem;
    color: $textColor;
    text-transform: uppercase;
  }

  p {
    font-size: 16px;
  }

  @media all and (max-device-width: 768px) {
    .title-section {
      font-size: 18px;
    }
  }
}
</style>
